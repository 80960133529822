import 'static/assets/css/froala-table.css';
import 'static/assets/css/tailwind.css';

import * as Sentry from '@sentry/react';
import { intercomNode } from 'integrations/intercom';
import { initializeSegmentTrackers } from 'integrations/segment';
import Cookies from 'js-cookie';
import RouteChangeIndicator from 'lib/route-change-indicator';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import { useEffect } from 'react';
import { appWithTranslation } from '../src/i18n';

const isServer = typeof window === 'undefined';
declare global {
  interface Window {
    analytics: any;
  }
}

type BHAppProps = AppProps & {
  token: string;
};

if (!isServer) {
  require('lib/initialize-client');

  // Enable ant animations on load
  window.addEventListener('load', function () {
    const el = document.getElementById('holderStyle');

    if (el) {
      el.parentNode.removeChild(el);
    }
  });
}

const BHApp = ({ Component, pageProps, router, token }: BHAppProps) => {
  useEffect(() => {
    const { user } = pageProps?.initialReduxState?.currentUser || {};
    const { site } = pageProps?.initialReduxState?.currentSite || {};
    // 'current_language' cookie is being set to validate language change for rendering iframe pages which are from ehqv2.
    //Since, i18next languageChanged event in iframe.js was getting triggered everytime on
    //switching across the sidebar menus and was reloading the iframe pages initially when switching from ehqv2 pages to BH pages.
    //This was casuing a glitch.
    Cookies.set('current_language', site?.locale, {
      expires: 2,
      path: '/',
      secure: true,
      sameSite: 'Strict',
    });

    const isAdminSegmentEnabled: boolean =
      pageProps?.initialReduxState?.features?.features[
        'segment_analytics_admin'
      ]?.enabled;
    const isParticipantSegmentEnabled: boolean =
      pageProps?.initialReduxState?.features?.features[
        'segment_analytics_participant'
      ]?.enabled;

    initializeSegmentTrackers(
      user,
      site,
      isAdminSegmentEnabled,
      isParticipantSegmentEnabled,
    );

    // This will be removed once schmaltz API's are implemented with cookie forwarding
    if (token) {
      sessionStorage.setItem('unexpected_thunder', token);
    }
  }, []);

  return (
    <>
      <Component {...pageProps} />
      <RouteChangeIndicator />
      {intercomNode({ router, pageProps })}
    </>
  );
};

BHApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  // @ts-ignore
  const token = appContext.ctx.req?.cookies['unexpected_thunder'] || null;
  return { ...appProps, token };
};

export default appWithTranslation(
  Sentry.withProfiler(BHApp, { name: 'blue-haven' }),
);
